.links .col-sm-12 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
@media (min-width: 992px) {
.links .col-sm-12 {
    text-align: left;
}
}
.links .linkTitle, .links .linkTitle a {
  color: #333333;
  font-size: 16px !important;
  font-weight: 600;
  margin-bottom: 1rem !important;
}
.links .linkList a {
  color: #333333;
  font-size: 13px;
  font-weight: 600;
  display: block;
  margin-bottom: 0.5rem;
}
.info {
  text-align: center;
  padding-top: 2rem;
}
.footer-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  font-size: 14px;
}
.footer-bottom > *:not(:last-child) {
  margin-bottom: 20px;
}
.footer-bottom a {
  color: #333333;
}
.footer-bottom svg {
  margin: 0 10px;
}
@media (min-width: 992px) {
.footer-bottom {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.footer-bottom > *:not(:last-child) {
    margin-bottom: 0;
}
.footer-bottom > * {
    -webkit-box-flex: 33%;
        -ms-flex: 33%;
            flex: 33%;
    width: 33%;
    text-align: center;
}
}